
global.$ = global.jQuery = require('jquery');
window.Swal = require('Sweetalert2');
window.AOS = require('aos');

// NAVBAR HAMBURGER JQUERY
var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};
 
var hamburgers = document.querySelectorAll(".hamburger");
if (hamburgers.length > 0) {
    forEach(hamburgers, function(hamburger) {
        hamburger.addEventListener("click", function() {
            this.classList.toggle("is-active");
        }, false);
    });
} 

// SIDEBAR NAV TOGGLE
const hamburger_navbar_toggler = document.querySelector('.hamburger-navbar-icon-toggler')
const backTop = document.querySelector('#back-to-top') 
const header_objects = document.querySelector('#headerNav')
const sub_container = document.querySelector('.sub-parent-group-container')

hamburger_navbar_toggler.addEventListener('click', () => {
    // HORIZONTAL LAYOUT APPLICABLE
    header_objects.classList.toggle('active-sidebar')
    if( header_objects!= 'active-sidebar' ) {
        sub_container.classList.toggle('active-fullPage')
        hamburger_navbar_toggler.classList.toggle('activeMode')
    } 
    // HORIZONTAL LAYOUT APPLICABLE
})
backTop.addEventListener('click', () => {
    var x = 'is-active';
    var hamburger = $(".hamburger")
    if(hamburger.hasClass(x)) {
        hamburger_navbar_toggler.click()
    } 
})

// HEADER NAV TOGGLE
$('#sidebar-menu ul li a').on('click', function() {
    $('#sidebar-menu ul').find('li.active').removeClass('active')
    $(this).parent().addClass('active')
})
// $('#back-to-top').on('click', function() {
//     $('.nav-item').removeClass('active')
//     setTimeout(() => {
//         $('#main').addClass('active')
//     }, 500);
// })

$(function() {
    // VERTICAL LAYOUT APPLICABLE   
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
            if (all) {
                selectEl.forEach(e => e.addEventListener(type, listener))
            } else {
                selectEl.addEventListener(type, listener)
            }
        }
    }
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    let navbarlinks = select('#sidebar-menu .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
                let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    const selectHeader = document.querySelector('#headerNav')
    let backtotop = select('.back-to-top');
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    // PRELOADER
    let preloader = select('#preloader');
    if (preloader) {
            window.addEventListener('load', () => {
            preloader.remove()
        });
    }
});  

// SLIDER
var swiper_review = new Swiper(".swiper-review", {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,
    slidesPerGroup: 1,
    centeredSlides: true,
    loopFillGroupWithBlank: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        769: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 200
        },
        425: {
            slidesPerView: 1,
            spaceBetween: 200
        }
    },
});
var swiper_gallery_thumbnail = new Swiper(".swiper-gallery-thumbnail", {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    autoHeight: true,
});
var swiper_gallery = new Swiper(".swiper-gallery", {
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: swiper_gallery_thumbnail,
    },
});

// REVEAL ANIMATION
window.addEventListener('load', () => {
    AOS.init({
        duration: 700,
        easing: 'ease-in-out',
        once: false,
        mirror: true,
    })
});

// PARALLAX

// EMAIL
$(function() {

    $("#butsave").on('click', function(event) { 
        
        event.preventDefault();
        var name = $('#name').val();
        var email = $('#email').val();
        var phone = $('#phone').val();
        var msg = $('#message').val();
        $('#butsave').prop("disabled",true);

        if(!mailValidator(email)) {
            $("#aemail").html("<span><i class='bi bi-exclamation-square-fill'></i> error email address.</span>").css("color","#eb8188");
            $("#butsave").removeAttr('disabled');
        }
        if(!phoneValidator(phone)) {
            $("#aphone").html("<span><i class='bi bi-exclamation-square-fill'></i> error phone number.</span>").css("color","#eb8188");
            $("#butsave").removeAttr('disabled');
        }

        if(name=='' || email=='' || phone=='' || msg=='') {

            if (name == '') {
                $("#aname").html("<span><i class='bi bi-exclamation-square-fill'></i> empty! fill in name.</span>").css("color","#eb8188");
            }
            if (email == '') {
                $("#aemail").html("<span><i class='bi bi-exclamation-square-fill'></i> empty! fill in email.</span>").css("color","#eb8188");
            }
            if (phone == '') {
                $("#aphone").html("<span><i class='bi bi-exclamation-square-fill'></i> empty! fill in phone.</span>").css("color","#eb8188");
            }
            if (msg == '') {
                $("#amessage").html("<span><i class='bi bi-exclamation-square-fill'></i> empty! fill in message.</span>").css("color","#eb8188");
            }
            $("#butsave").removeAttr('disabled');

        } else if(!mailValidator(email)) {
            $("#aemail").html("<span><is class='bi bi-exclamation-square-fill'></i> error email.</span>").css("color","#eb8188");
            $("#butsave").removeAttr('disabled');
        } else if(!phoneValidator(phone)) {
            $("#aphone").html("<span><i class='bi bi-exclamation-square-fill'></i> error phone number.</span>").css("color","#eb8188");
        } else {
            $('#loading').prepend('<span class="spinner-border text-info" role="status" aria-hidden="true"></span>');

            $.ajax({
                url:'./mail.php',
                type:'POST',
                data: {addData:1,name:name,email:email,phone:phone,message:msg},
                cache:false,
                success:function(response) {
                    console.log(response);
                    var decode = JSON.parse(response);
                    if(decode.statusCode == 200) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Mail sent.',
                            showConfirmButton: false,
                            timer: 1800
                        });
                        $('#name').val("");
                        $('#email').val("");
                        $('#phone').val("");
                        $('#message').val("");
                        $('#loading').remove();	
                        $("#butsave").removeAttr('disabled');
                    }
                    if(decode.statusCode == 400) {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Something went wrong. Please try again.',
                            showConfirmButton: false,
                            timer: 1800
                        });
                        $('#name').val("");
                        $('#email').val("");
                        $('#phone').val("");
                        $('#message').val("");
                        $('#loading').remove();	
                    }	
                }   
            });
        }
    });
});
function mailValidator(email) {
	var filter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if (!filter.test(email)) {
		return false;
	} else {
		return true;
	}
}
function phoneValidator(phone) {
    var filter = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
    if(!filter.test(phone)) {
        return false;
    } else {
        return true;
    }
}  
